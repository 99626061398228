import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Typography} from 'spenda-ui-react';
import {IconPaymentMethods} from '../../../assets/svg/IconPaymentMethods';
import {ARSearchBar, IARSearchBarSearch} from '../../../components/AccountsReceivable/ARSearchBar';
import {ARTable} from '../../../components/AccountsReceivable/ARTable';
import {PSBLFooter} from '../../../components/AccountsReceivable/PSBLFooter';
import PaymentMethodsTooltip from '../../../components/data-display/PaymentMethodsTooltip';
import {Layout} from '../../../components/layout/Layout';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import {MarketplaceLayout} from '../../purchasing/MarketplaceLayout';
import {
  ARPaymentMethodsFilters,
  PaymentsFilterDropdown,
  renderPaymentMethodIcons,
  renderPaymentStatus,
} from '../payments/ARPayments';
import {useTransactionViewHook} from '../../../hooks/useARHook';
import {TransactionView} from '../../../components/AccountsReceivable/TransactionView';
import {useCustomerStatementsAPI} from '../../../services/useCustomerStatementsAPI';
import {IPaidInvoice, IPaidInvoicesResponse} from '../../../model/customer/CustomerStatements';
import {ARPaymentMethodIconsStatus, DatTypes} from '../../../model/constants/Constants';
import moment from 'moment';
import {PriceFormat} from '../../../utils/formatter';
import {useSupplierTransactionsAPI} from '../../../services/useSupplierTransactionsAPI';
import AppContext from '../../../context/app/appContext';
import {IconDropdownSupplierView} from '../../../assets/svg/accounts-payable/IconDropdownSupplierView';
import {VisibleContent} from '../../../components/ui/VisibleContent';

const InitialSeach = {searchString: '', searchCategory: 'RefNumber'};
const defaultPaymentMethodFilters = Object.values(ARPaymentMethodsFilters);

export const PaidInvoices = () => {
  // Hooks
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();
  const {marketplaceSupplier} = useContext(AppContext);
  const {customerId: cId, supplierId: sId} = useParams<{customerId?: string; supplierId?: string}>();
  const {viewingTx, handleCloseTx} = useTransactionViewHook();

  // States
  const [search, setSearch] = useState<IARSearchBarSearch>(InitialSeach);
  const [paidInvoicesFilterBy, setPaidInvoicesFilterBy] = useState<string[][]>(defaultPaymentMethodFilters);
  const [expandedCustomer, setExpandedCustomer] = useState<IPaidInvoicesResponse>();
  const [paidInvoicesData, setPaidInvoicesData] = useState<IPaidInvoicesResponse[]>();

  // APIs
  const {getSupplierPaidInvoices, isLoading: isSupplierLoading} = useCustomerStatementsAPI();
  const {getBuyerPaidInvoices, isLoading: isBuyerLoading} = useSupplierTransactionsAPI();

  // Constant
  const isLoading = isSupplierLoading || isBuyerLoading;
  const supplierId = Number(sId) || undefined;
  const customerId = Number(cId) || undefined;
  const searchCategories = [
    {value: 'RefNumber', label: 'Payment Ref'},
    {value: 'Amount', label: 'Amount'},
  ];

  const filterRecords = useCallback(
    (list: IPaidInvoice[] | undefined) => {
      return list?.reduce((a: IPaidInvoice[], p: IPaidInvoice) => {
        const isValid = p?.paymentMethodList?.some((r: string) => paidInvoicesFilterBy?.flat()?.includes(r));

        if (isValid) {
          a?.push(p);
        }
        return a;
      }, []);
    },
    [paidInvoicesFilterBy],
  );

  const txList = useMemo(() => {
    let list: IPaidInvoice[] | undefined = [];

    if (isInSupplierContext && !customerId) {
      list = expandedCustomer?.paidInvoices;
    }
    list = paidInvoicesData?.[0]?.paidInvoices;

    return filterRecords(list)?.map(t => ({
      txId: t.invoiceID,
      txGuid: t.invoiceGUID,
      txDatType: DatTypes.Invoice,
    }));
  }, [filterRecords, customerId, isInBuyerContext, isInSupplierContext, expandedCustomer?.paidInvoices]);

  useEffect(() => {
    setExpandedCustomer(undefined);
    fetchPaidInvoices();
  }, [search]);

  const fetchPaidInvoices = async () => {
    try {
      isInSupplierContext && (await fetchSupplierPaidInvoices());
      isInBuyerContext && (await fetchBuyerPaidInvoices());
    } catch {}
  };

  const fetchSupplierPaidInvoices = async () => {
    try {
      const query = {
        accountCustomerID: customerId || undefined,
        searchField: search?.searchString ? search?.searchCategory : undefined,
        searchString: search?.searchString || undefined,
      };
      const response = await getSupplierPaidInvoices(query);
      setPaidInvoicesData(response);
    } catch {}
  };

  const fetchBuyerPaidInvoices = async () => {
    try {
      if (!supplierId) return;
      const query = {
        searchField: search?.searchString ? search?.searchCategory : undefined,
        searchString: search?.searchString || undefined,
      };
      const response = await getBuyerPaidInvoices(supplierId, query);
      setPaidInvoicesData(response);
    } catch {}
  };

  const isSortable = VisibleContent({
    keyPath: 'accountReceivables.paidInvoices.table.header.sortAble',
    children: true,
  }) as boolean;

  const paidInvoicesGroupByCustomer = [
    {
      title: '',
      key: 'vendor-dropdown',
      width: '2%',
      columClassName: `text-spenda-labeltext !max-w-[32px] !min-w-[32px] ${isInBuyerContext ? 'hidden' : ''}`,
      rowClassName: `p-0 !max-w-[32px] !min-w-[32px] ${isInSupplierContext ? '!bg-primary-background' : 'hidden'}`,
      align: 'left',
      rowRenderer: (rowData: IPaidInvoicesResponse) => {
        return (
          <>
            <span
              data-autoid={`btnRowVendor-${rowData.customer}`}
              className={`togglerIcon m-auto mr-[8px] flex !h-[14px] !w-[14px] cursor-pointer items-center justify-center rounded-[3px] border border-primary ${
                rowData?.customer !== expandedCustomer?.customer ? '' : '!rotate-180'
              }`}
              onClick={() => {
                if (rowData?.customer === expandedCustomer?.customer) setExpandedCustomer(undefined);
                else setExpandedCustomer(rowData);
              }}
            >
              <IconDropdownSupplierView className="w-[7px] rotate-180 transform" />
            </span>
          </>
        );
      },
    },
    {
      title: 'Customer Name',
      key: 'customerName',
      width: '20%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: isSortable,
      rowRenderer: (rowData: IPaidInvoicesResponse) => {
        return (
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
              <Typography
                data-autoid={`lblCustomer-${rowData?.customer}`}
                className="inline overflow-ellipsis font-medium"
                variant="paragraph"
              >
                {rowData?.customer}
              </Typography>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Invoice No',
      key: 'refNumber',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: isSortable,
      getRowClassConditionally: (rowData: IPaidInvoicesResponse) =>
        rowData?.customer === expandedCustomer?.customer ? '!bg-primary-background' : '',
      rowRenderer: () => <span className="overflow-ellipsis font-poppins text-base font-medium text-black-800"></span>,
    },
    {
      title: 'Payment Date',
      key: 'paymentDateTime_utc',
      width: '15%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: isSortable,
      getRowClassConditionally: (rowData: IPaidInvoicesResponse) =>
        rowData?.customer === expandedCustomer?.customer ? '!bg-primary-background' : '',
      rowRenderer: () => <span className="overflow-ellipsis font-poppins text-base font-medium text-black-800"></span>,
    },
    {
      title: 'Invoice total',
      key: 'invoiceAppliedAmount',
      width: '12%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: isSortable,
      getRowClassConditionally: (rowData: IPaidInvoicesResponse) =>
        rowData?.customer === expandedCustomer?.customer ? '!bg-primary-background' : '',
      rowRenderer: (rowData: IPaidInvoicesResponse) => (
        <span className="overflow-ellipsis font-poppins text-base font-medium text-black-800">
          {PriceFormat(rowData?.totalInvoices, undefined, 2)}
        </span>
      ),
    },
    {
      title: 'Credit Applied',
      key: 'creditNoteAppliedAmount',
      width: '12%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: isSortable,
      getRowClassConditionally: (rowData: IPaidInvoicesResponse) =>
        rowData?.customer === expandedCustomer?.customer ? '!bg-primary-background' : '',
      rowRenderer: (rowData: IPaidInvoicesResponse) => (
        <span className="overflow-ellipsis font-poppins text-base font-medium text-black-800">
          {PriceFormat(rowData?.totalAppliedCreditNotes, undefined, 2)}
        </span>
      ),
    },
    {
      title: 'Payment Amount',
      key: 'appliedPayments',
      width: '12%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: isSortable,
      getRowClassConditionally: (rowData: IPaidInvoicesResponse) =>
        rowData?.customer === expandedCustomer?.customer ? '!bg-primary-background' : '',
      rowRenderer: (rowData: IPaidInvoicesResponse) => (
        <span className="overflow-ellipsis font-poppins text-base font-medium text-black-800">
          {PriceFormat(rowData?.totalAppliedPayments, undefined, 2)}
        </span>
      ),
    },
    {
      title: 'Payment Status',
      key: 'status',
      width: '15%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: isSortable,
      getRowClassConditionally: (rowData: IPaidInvoicesResponse) =>
        rowData?.customer === expandedCustomer?.customer ? '!bg-primary-background' : '',
      rowRenderer: () => <div className="relative h-[20px]" />,
    },
  ];

  const paidInvoicesColums = [
    {
      title: '',
      width: '2%',
      key: 'vendor-dropdown',
      columClassName: `text-spenda-labeltext !max-w-[32px] !min-w-[32px] ${isInBuyerContext ? 'hidden' : ''}`,
      rowClassName: `p-0 !max-w-[32px] !min-w-[32px] ${isInSupplierContext ? '!bg-primary-background' : 'hidden'}`,
      align: 'left',
      isSortable: false,
      rowRenderer: () => {
        return <></>;
      },
    },
    {
      title: 'Supplier Name',
      key: 'supplierName',
      width: '20%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaidInvoice) => {
        return (
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
              <Typography
                data-autoid={`lblAccountCustomerName`}
                className="inline overflow-ellipsis font-medium"
                variant="paragraph"
              >
                {isInBuyerContext ? marketplaceSupplier?.TenantName : rowData?.accountCustomerName}
              </Typography>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Invoice No',
      key: 'refNumber',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaidInvoice) => {
        const path = `?txGuid=${rowData.invoiceGUID}&txDatType=${DatTypes.Invoice}&txId=${rowData.invoiceID}`;
        return (
          <div className="flex flex-row items-center justify-between">
            <Link
              to={{
                pathname: location.pathname,
                search: path,
              }}
            >
              <Typography
                data-autoid={`lnkInvoiceRefNumber`}
                className="inline cursor-pointer overflow-ellipsis font-medium text-primary underline"
                variant="paragraph"
              >
                {rowData?.refNumber}
              </Typography>
            </Link>
            <div className="flex flex-row flex-wrap justify-end gap-x-2">
              <PaymentMethodsTooltip
                title={
                  <React.Fragment>
                    <div data-autoid={`rowPaymentHistory<allocationID`} className="flex flex-row justify-start gap-2">
                      {rowData?.paymentMethodList?.map((t, i) => {
                        return (
                          <Typography
                            data-autoid={`imgPaymentMethod${t}`}
                            key={i}
                            variant="paragraph"
                            className="inline"
                          >
                            {renderPaymentMethodIcons(t as ARPaymentMethodIconsStatus)}
                          </Typography>
                        );
                      })}
                    </div>
                  </React.Fragment>
                }
              >
                <button
                  type="button"
                  data-autoid={'imgPaymentMethod'}
                  className="flex cursor-pointer items-center justify-center"
                >
                  <IconPaymentMethods />
                </button>
              </PaymentMethodsTooltip>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Payment Date',
      key: 'paymentDate',
      width: '15%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaidInvoice) => {
        return (
          <Typography
            data-autoid={`lblPaymentDate`}
            className="inline overflow-ellipsis font-medium"
            variant="paragraph"
          >
            {moment(rowData?.paymentDate).format('DD MMM YYYY')}
          </Typography>
        );
      },
    },
    {
      title: 'Invoice total',
      key: 'invoiceTotalAmount',
      width: '12%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaidInvoice) => {
        return (
          <Typography
            data-autoid={`lblInvoiceTotal`}
            className="inline overflow-ellipsis font-medium"
            variant="paragraph"
          >
            {PriceFormat(rowData?.invoiceTotalAmount, undefined, 2)}
          </Typography>
        );
      },
    },
    {
      title: 'Credit Applied',
      key: 'creditNoteAppliedAmount',
      width: '12%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaidInvoice) => {
        return (
          <Typography
            data-autoid={`lblCreditApplied`}
            className="inline overflow-ellipsis font-medium"
            variant="paragraph"
          >
            {PriceFormat(rowData?.creditNoteAppliedAmount, undefined, 2)}
          </Typography>
        );
      },
    },
    {
      title: 'Payment Amount',
      key: 'paymentAppliedAmount',
      width: '12%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaidInvoice) => {
        return (
          <div className="relative h-[20px]">
            <Typography
              data-autoid={`lblPaymentAmount`}
              className="inline overflow-ellipsis font-medium"
              variant="paragraph"
            >
              {PriceFormat(rowData?.paymentAppliedAmount, undefined, 2)}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Payment Status',
      key: 'status',
      width: '15%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5 font-poppins ${isInSupplierContext ? '!bg-primary-background' : ''}`,
      isSortable: true,
      rowRenderer: (rowData: IPaidInvoice) => {
        return renderPaymentStatus(rowData?.status);
      },
    },
  ];

  const getCols = useMemo(() => {
    if (isInSupplierContext && !customerId) {
      return paidInvoicesGroupByCustomer;
    }
    return paidInvoicesColums;
  }, [customerId, isInSupplierContext, paidInvoicesColums, paidInvoicesGroupByCustomer]);

  const getRows = useMemo(() => {
    if (isInSupplierContext && !customerId) {
      return paidInvoicesData;
    }
    return filterRecords(paidInvoicesData?.[0]?.paidInvoices);
  }, [customerId, filterRecords, isInSupplierContext, paidInvoicesData]);

  const paidInvoiceCount = useMemo(() => {
    if (isInSupplierContext && !customerId) {
      return getRows?.reduce((a, c: IPaidInvoicesResponse) => a + c?.paidInvoices?.length, 0);
    }
    return getRows?.length;
  }, [getRows, isInSupplierContext, customerId]);

  const getRowDataAutoId = (row: IPaidInvoice | IPaidInvoicesResponse) => {
    if (isInSupplierContext && !customerId) {
      return `rowPaidInvoices-${(row as IPaidInvoicesResponse)?.customer}`;
    }
    return `rowPaidInvoices${(row as IPaidInvoice)?.invoiceID}`;
  };

  const getRowNestedDataAutoId = (row: IPaidInvoice) => {
    return `rowPaidInvoices${row?.invoiceID}`;
  };

  const handleRenderNestedTable = (row: IPaidInvoicesResponse, order: 'asc' | 'desc', orderBy: string) => {
    if (expandedCustomer?.customer !== row?.customer) return;
    return (
      <ARTable
        isPaginated
        isHighlightRowOnHover
        isNestedTable
        rows={filterRecords(expandedCustomer?.paidInvoices) || []}
        columns={paidInvoicesColums}
        isLoading={isLoading}
        order={order}
        orderBy={orderBy}
        getRowDataAutoId={getRowNestedDataAutoId}
      />
    );
  };

  const leftPannel = (
    <div className={`relative flex h-full flex-col overflow-hidden rounded-md bg-primary-background`}>
      {viewingTx && (
        <TransactionView
          key={`${viewingTx?.txGuid}${viewingTx?.txId}${viewingTx?.txDatType}`}
          isShowInPdfView={true}
          transactionId={viewingTx?.txId}
          txDatType={viewingTx?.txDatType}
          txGuid={viewingTx?.txGuid}
          handleOnClosePdfView={() => handleCloseTx()}
          supplierId={supplierId}
          customerId={customerId}
          txList={txList}
          scope="PaidInvoices"
        />
      )}
      <div className="h-[inherit] w-full">
        <div className={`invoicesList h-full w-full rounded bg-white pb-16`}>
          <div className={`flex items-center justify-between border-b border-[#D8D8D8] p-2.5 pb-[10px]`}>
            <Typography className="font-light" data-autoid={`lblRecordLengthPaidInvoices`} variant="h2">
              {`Paid Invoices (${paidInvoiceCount || 0})`}
            </Typography>
            <div className="flex flex-row items-center justify-end">
              <div className="min-w-[380px]">
                <ARSearchBar
                  reverse
                  type="advance"
                  searchCategories={searchCategories}
                  dataAutoIdSuffix={'paidInvoices'}
                  searchString={search?.searchString || ''}
                  searchCategory={search?.searchCategory || 'RefNumber'}
                  isSearching={false}
                  onSubmit={searchData => setSearch(searchData)}
                />
              </div>
              <VisibleContent keyPath="accountsReceivable.paidInvoices.paymentMethodsFilter">
                <div className="pl-3">
                  <PaymentsFilterDropdown
                    filterBy={paidInvoicesFilterBy}
                    setFilterBy={setPaidInvoicesFilterBy}
                    methodOptionObject={ARPaymentMethodsFilters}
                  />
                </div>
              </VisibleContent>
            </div>
          </div>
          <div className={`mt-4 flex h-full w-full bg-white`}>
            <div className="w-full overflow-auto">
              <ARTable
                isPaginated
                isHighlightRowOnHover
                tableClass="mb-12"
                isLoading={isInBuyerContext && !marketplaceSupplier?.TenantName ? true : isLoading}
                columns={getCols}
                rows={getRows || []}
                dataAutoId="PaidInvoices"
                renderNestedTable={isInSupplierContext && !customerId ? handleRenderNestedTable : undefined}
                getRowDataAutoId={getRowDataAutoId}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
      {isInBuyerContext && (
        <>
          <MarketplaceLayout leftPanel={leftPannel} splitWidthType={4} />
          <PSBLFooter />
        </>
      )}
      {isInSupplierContext && <Layout leftPanel={leftPannel} splitWidthType={4} />}
    </div>
  );
};
