import {SALES_ORDER_STATUS_LABEL} from '../../model/constants/Constants';
import {ITheme} from '../../model/theme/Theme';
import CapricornLogo from './CapricornLogo';

const CapricornDSDTheme: ITheme = {
  rootClassName: 'capricorn',
  title: 'Capricorn Ecommerce Transactions',
  logo: CapricornLogo,
  content: {
    header: {
      moduleName: true,
    },
    // settings: {
    //   businessDetails: true,
    //   businessLocations: true,
    //   betaFeatures: true,
    // },
    accountReceivables: {
      paidInvoices: {
        table: {
          header: {
            sortAble: true,
          },
        },
      },
    },
    notifications: true,
    zohoChat: true,
    salesOrders: {
      customerOrderDetails: {
        editCustomerButton: true,
        customerRefInput: true,
        disclaimer: true,
        editOrdersButton: true,
        deleteOrderButton: true,
        invoicePickslipQuote: true,
        stepper: true,
        iconDownload: true,
        backButton: false,
      },
      dashboard: {
        tabs: true,
        createOrderButton: true,
      },
    },
    accountsReceivable: {
      paidInvoices: {
        paymentMethodsFilter: true,
      },
      menu: {
        accountsReceivable: true,
        payments: true,
      },
    },
  },
  personalizedContent: {
    salesOrders: {
      dashboard: {
        status: {
          Approved: SALES_ORDER_STATUS_LABEL.Approved,
        },
      },
      customerOrderDetails: {
        status: {
          Approved: SALES_ORDER_STATUS_LABEL.Approved,
          Cancelled: SALES_ORDER_STATUS_LABEL.Cancelled,
          Open: SALES_ORDER_STATUS_LABEL.Open,
          Reserved: SALES_ORDER_STATUS_LABEL.Reserved,
        },
      },
    },
    accountReceivables: {
      header: {
        subMenu: {
          'Paid Invoices': 'Invoiced Sales Orders',
        },
      },
      paymentHistory: {
        status: {
          paid: 'Invoiced',
        },
      },
    },
  },
};

export default CapricornDSDTheme;
